import { isAuth } from "../../../auth/ProtectedRoute"
import React, { FC, Fragment, useEffect, useState } from "react"
import swal from "sweetalert"

interface PendingPayment {
  id: number
  trx_ref: string
  project_id: number
  amount: string
}

interface Member {
  member_id: number
  profile_picture: string
  full_name: string
  email: string
  pending_payments: PendingPayment[]
  country_name: string
  country_code: string
  country_id: number
}

interface Props {
  projectId: number
}

const PendingTransaction: FC<Props> = ({ projectId }) => {
  const [member, setMember] = useState<Member[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [processing, setProcessing] = useState<number[]>([])

  useEffect(() => {
    if (projectId) {
      fetchPendingTransactions()
    }
  }, [projectId])

  const fetchPendingTransactions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_HOST_API}payment/pending-payments`, {
        headers: {
          Authorization: `Bearer ${isAuth()}`,
        },
      })
      const data = await response.json()
      if (data.response && data.status === "SUCCESS") {
        const filteredMembers = data.data
          .map((m: Member) => ({
            ...m,
            pending_payments: m.pending_payments.filter(
              payment => payment.project_id === projectId
            ),
          }))
          .filter((m: Member) => m.pending_payments.length > 0)

        setMember(filteredMembers)
      }
    } catch (error) {
      console.error("Error fetching transactions:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleResolve = async (projectId: number, transactionId: string, paymentId: number) => {
    setProcessing(prev => [...prev, projectId])
    try {
      const formData = new FormData()
      formData.append("project_id", projectId.toString())
      formData.append("stripe_transaction_id", transactionId)

      const response = await fetch(`${process.env.REACT_APP_HOST_API}payment/resolvePayment`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${isAuth()}`,
        },
        body: formData,
      })

      const data = await response.json()
      if (data.response && data.status === "SUCCESS") {
        // Immediately remove the resolved payment from state
        setMember(prevMembers =>
          prevMembers
            .map(m => ({
              ...m,
              pending_payments: m.pending_payments.filter(p => p.id !== paymentId),
            }))
            .filter(m => m.pending_payments.length > 0)
        )

       swal("Success", "Payment successful!", "success")
      } else {
        swal("Error", "Payment not successful. Please try again.", "error")
      }
    } catch (error) {
      console.error("Error resolving payment:", error)
      swal("Error", "Payment not successful. Please try again.", "error")
    } finally {
      setProcessing(prev => prev.filter(id => id !== projectId))
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (!projectId) {
    return null
  }

  if (member.length === 0) {
    return (
      <div className="detailsx flex items-center justify-center p-8">
        <h2>No pending transactions for this project</h2>
      </div>
    )
  }

  return (
    <Fragment>
      {member.map(member => (
        <div key={member.member_id} className="detailtsx">
          <div className="left">
            <div className="left_img">
              <img
                src={process.env.REACT_APP_IMAGE_API_PREFIX + `${member.profile_picture}`}
                alt="profile"
              />
            </div>
            <div className="left_box">
              <h1>{member.full_name}</h1>
              <h2>{member.country_name}</h2>
            </div>
          </div>
          <div className="right">
            {member.pending_payments.map(payment => (
              <div key={payment.id}>
                <h1>${payment.amount}</h1>
                <div>
                  <button
                    onClick={() => handleResolve(payment.project_id, payment.trx_ref, payment.id)}
                    disabled={processing.includes(payment.project_id)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
                  >
                    {processing.includes(payment.project_id) ? "Processing..." : "Resolve"}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </Fragment>
  )
}

export default PendingTransaction
