import React, { Fragment, useContext, useEffect, useState } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"
import axios from "axios"
import { Dropdown, Menu, message } from "antd"
import { useNavigate, Link } from "react-router-dom"
import { isAuth, SidebarContext } from "../../auth/ProtectedRoute"
import "../../../styles/feed.scss"
import { useInView } from "react-intersection-observer"
import { CircularProgress } from "@mui/material"

// Update interfaces to match new API response
interface Member {
  id: number
  name?: string
  fullname?: string
  profession?: string
  image?: string
  profile_image?: string
  profile_code?: string
  connections?: number
}

interface Page {
  id: number
  name?: string
  link?: string
  description?: string
  industry?: string
  size?: string
  logo?: string
  image?: string
  followers?: number
}

// Interface for Group data
interface Group {
  id: number
  name?: string
  link?: string
  description?: string
  logo?: string
  image?: string
  followers?: number
  // Add any other group-specific properties here
}

// Update PostableData to include all three types
interface PostableData {
  type: "member" | "page" | "group"
  data?: Member | Page | Group
}

interface FeedItem {
  id: number
  postable: PostableData
  content: string
  media: string[] | []
  member: Member
  connect_status?: string
  likes: number
  like: Member[]
  shares: number
  comments: number
  comment: Comment[]
  created_at: string
  updated_at: string | null
}

interface PaginationData {
  current_page: number
  data: FeedItem[]
  total: number
  last_page: number
}

interface FeedResponse {
  response: boolean
  status: number
  data: FeedItem[]
  pagination: {
    total: number
    per_page: number
    current_page: number
    last_page: number
  }
}

const isPageData = (data: Member | Page | Group): data is Page => {
  return "industry" in data && "logo" in data
}

const isGroupData = (data: Member | Page | Group): data is Group => {
  return "logo" in data && !("industry" in data)
}

const Feed: React.FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  const navigate = useNavigate()
  const { ref, inView } = useInView()

  const [searchTerm, setSearchTerm] = useState<string>("")

  const fetchFeeds = async ({
    pageParam = 1,
    searchTerm = "",
  }: {
    pageParam?: number
    searchTerm?: string
  }): Promise<PaginationData> => {
    try {
      const apiUrl = searchTerm
        ? `${process.env.REACT_APP_HOST_API}manage-feeds/feed/search`
        : `${process.env.REACT_APP_HOST_API}manage-feeds/feed`

      const response = await axios.get<FeedResponse>(apiUrl, {
        params: {
          page: pageParam,
          limit: 20,
          search_term: searchTerm,
        },
        headers: {
          Authorization: `Bearer ${isAuth()}`,
        },
      })

      // For search results, use the data directly
      const feedData = searchTerm ? response.data.data : response.data.data || []

      return {
        current_page: searchTerm ? 1 : response.data.pagination.current_page,
        data: feedData,
        total: searchTerm ? feedData.length : response.data.pagination.total,
        last_page: searchTerm ? 1 : response.data.pagination.last_page,
      }
    } catch (error) {
      console.error("Error fetching feeds:", error)
      return {
        current_page: 1,
        data: [],
        total: 0,
        last_page: 1,
      }
    }
  }

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, refetch, isLoading } =
    useInfiniteQuery({
      queryKey: ["feeds", searchTerm],
      queryFn: ({ pageParam }) => fetchFeeds({ pageParam, searchTerm }),
      getNextPageParam: lastPage => {
        return lastPage.current_page === lastPage.last_page ? undefined : lastPage.current_page + 1
      },
      initialPageParam: 1,
    })

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchTerm(value)
  }

  useEffect(() => {
    refetch()
  }, [searchTerm, refetch])

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
  }

  // // Update the component code
  // const renderFeedHeader = (feed: FeedItem) => {
  //   const { postable, created_at } = feed
  //   const headerInfo = postable.data
  //   const isPage = postable.type === "page"
  //   const isGroup = postable.type === "group"

  //   // Get name based on type
  //   const name = isPage || isGroup ? headerInfo?.name : (headerInfo as Member)?.fullname

  //   // Get profile image based on type
  //   const profileImage =
  //     isPage || isGroup
  //       ? headerInfo && (isPageType(headerInfo) || isGroupType(headerInfo))
  //         ? headerInfo.logo || headerInfo.image
  //         : undefined
  //       : (headerInfo as Member)?.profile_image || (headerInfo as Member)?.image

  //   // Get role/type based on type
  //   const role = isPage
  //     ? isPageType(headerInfo!)
  //       ? headerInfo.industry
  //       : "Page"
  //     : isGroup
  //     ? "Group"
  //     : (headerInfo as Member)?.profession || "Member"

  //   return (
  //     <div className="feed-item-header">
  //       <div className="user-info">
  //         <div className="user-avatar">
  //           <img
  //             src={
  //               feed.member?.profile_image
  //                 ? (process.env.REACT_APP_IMAGE_API_PREFIX as string) + feed.member.profile_image
  //                 : "/api/placeholder/40/40"
  //             }
  //             alt="User avatar"
  //           />
  //         </div>
  //         <div className="user-details">
  //           <h4>{feed.member?.fullname || "Unknown User"}</h4>
  //           <p className="user-role">{feed.member?.profession || "Member"}</p>
  //           <p className="post-time">{formatDate(feed.created_at)}</p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  const renderFeedHeader = (feed: FeedItem) => {
    const { postable, created_at, member } = feed

    // Get avatar/logo image based on post type
    const getDisplayImage = () => {
      if (postable.type === "page" && postable.data && isPageData(postable.data)) {
        return postable.data.logo
          ? `${process.env.REACT_APP_IMAGE_API_PREFIX}${postable.data.logo}`
          : "/api/placeholder/40/40"
      }
      // For members, use their image
      return member?.image
        ? `${process.env.REACT_APP_IMAGE_API_PREFIX}${member.image}`
        : "/api/placeholder/40/40"
    }

    // Get display name based on post type
    const getDisplayName = () => {
      if (postable.type === "page" && postable.data && isPageData(postable.data)) {
        return postable.data.name || "Unknown Page"
      }
      return member?.name || "Unknown User"
    }

    // Get role/type description
    const getDisplayRole = () => {
      if (postable.type === "page" && postable.data && isPageData(postable.data)) {
        return postable.data.industry || "Page"
      }
      return member?.profession || "Member"
    }

    return (
      <div className="feed-item-header">
        <div className="user-info">
          <div className="user-avatar">
            <img src={getDisplayImage()} alt={`${postable.type} avatar`} />
          </div>
          <div className="user-details">
            <h4>{getDisplayName()}</h4>
            <p className="user-role">{getDisplayRole()}</p>
            <p className="post-time">{formatDate(created_at)}</p>
          </div>
        </div>
        <div className="post-actions">
          <Dropdown overlay={getMenuForFeed(feed)} trigger={["click"]}>
            <button className="action-button">
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
          </Dropdown>
        </div>
      </div>
    )
  }

  const allFeeds = data?.pages.flatMap(page => page.data) || []

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage])

  // Existing utility functions
  const handleDeleteFeed = async (feedId: number) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST_API}manage-feeds/feed/${feedId}`,
        {
          headers: {
            Authorization: `Bearer ${isAuth()}`,
          },
        }
      )

      if (response.data.response) {
        message.success("Feed deleted successfully")
        // Refetch feeds after deletion
        refetch()
      } else {
        message.error("Failed to delete feed")
      }
    } catch (error) {
      console.error("Error deleting feed:", error)
      message.error("Error deleting feed")
    }
  }

  // const parseMedia = (mediaString: string) => {
  //   try {
  //     const mediaArray = JSON.parse(mediaString)
  //     return mediaArray
  //   } catch (error) {
  //     console.error("Error parsing media JSON:", error)
  //     return []
  //   }
  // }

  const getMenuForFeed = (feed: FeedItem) => (
    <Menu
      items={[
        {
          label: (
            <Link
              to={`/admin/content-management/feeds/view-feed/${feed.id}`}
              state={{ userName: userName, sidebar: sidebarData }}
            >
              view details
            </Link>
          ),
          key: "1",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        {
          label: "Delete Feed",
          key: "3",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
          onClick: () => {
            handleDeleteFeed(feed.id)
          },
        },
      ]}
    />
  )

  return (
    <Fragment>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        .loader-spinner {
          width: 50px;
          height: 50px;
          border: 4px solid #f3f3f3;
          border-top: 4px solid #3498db;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin: 20px auto;
        }
      `}</style>
      <div style={{ padding: "50px" }} className="roless">
        <div className="roless-wrapper">
          <div className="roless--heading">
            <div className="roless--heading-title">
              <h2>Feeds</h2>
            </div>
            <div className="roless--heading-img">
              <div className="formy">
                <form>
                  <div className="formy--box">
                    <input
                      type="text"
                      placeholder="Search feeds..."
                      name="search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <div className="formy--box-icon">
                      <i className="fa fa-search"></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="roless--tables">
            <div className="roless--tables--content">
              <div className="feed-container">
                <div className="feed-wrapper">
                  <div className="feed-items">
                    {isLoading ? (
                      <div className="no-feeds">Loading...</div>
                    ) : allFeeds.length > 0 ? (
                      allFeeds.map(feed => (
                        <div className={`feed-item feed-type-${feed.postable.type}`} key={feed.id}>
                          {renderFeedHeader(feed)}

                          <div className="feed-item-content">
                            <div dangerouslySetInnerHTML={{ __html: feed.content }} />

                            {feed.media && feed.media.length > 0 && (
                              <div
                                className={`feed-media grid-media media-${
                                  feed.media.length > 4 ? "more" : feed.media.length
                                }`}
                              >
                                {feed.media.slice(0, 6).map((mediaUrl: string, index: number) => {
                                  const isOverflow = feed.media.length > 6 && index === 5
                                  const overflowCount = feed.media.length - 6

                                  return (
                                    <div
                                      className={`media-item media-${feed.media.length} ${
                                        isOverflow ? "media-overflow" : ""
                                      }`}
                                      key={index}
                                      data-overflow={isOverflow ? `+${overflowCount}` : undefined}
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_API_PREFIX}${mediaUrl}`}
                                        alt={`Media ${index + 1}`}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>

                          <div className="feed-item-footer">
                            <div className="interaction-buttons">
                              <button className="like-button">
                                <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                <span>{feed.likes || 0}</span>
                              </button>
                              <button className="comment-button">
                                <i className="fa fa-comment" aria-hidden="true"></i>
                                <span>{feed.comments || 0}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-feeds">
                        {searchTerm ? `No feeds found for "${searchTerm}"` : "No feeds available"}
                      </div>
                    )}
                  </div>

                  {hasNextPage && (
                    <div
                      ref={ref}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {isFetchingNextPage && (
                        <CircularProgress
                          size="16px"
                          style={{
                            width: "50px",
                            height: "50px",
                            border: "4px solid #f3f3f3",
                            borderRadius: "50%",
                            animation: "spin 1s linear infinite",
                            margin: "20px auto",
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Feed
