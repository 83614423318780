import React, { Fragment } from "react"
import { ProjectData } from "../Types/DataType"

interface BiddingDisplayProps {
  project: ProjectData
}

const BiddingDisplay: React.FC<BiddingDisplayProps> = ({ project }) => {
  // Ensure project_biddings exists and is an array
  const projectBiddings = Array.isArray(project?.project_biddings) ? project.project_biddings : []

  // Get the first bidding with payment info if it exists
  const activeBidding =
    projectBiddings.length > 0
      ? projectBiddings.find(bid => bid.buyer_payment_temp !== undefined)
      : null

  if (projectBiddings.length === 0 || !activeBidding || !activeBidding.buyer_payment_temp) {
    return <div className="p-4 text-center text-gray-500">No active biddings available</div>
  }

  const { seller } = activeBidding.buyer_payment_temp

  return (
    <Fragment>
      <div className="details">
        <div className="left">
          <img
            src={`${process.env.REACT_APP_IMAGE_API_PREFIX}${seller.profile_image}`}
            alt={`${seller.fullname}'s profile`}
          />
        </div>
        <div className="right">
          <h1>{seller.fullname}</h1>
          <p>{seller.country.name}</p>
          <h5>{seller.profession}</h5>
          <h6>Bid Amount: ${parseFloat(activeBidding.bidding_amount).toLocaleString()}</h6>
        </div>
      </div>
    </Fragment>
  )
}

export default BiddingDisplay
