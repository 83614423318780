import React, { Fragment, useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { message } from "antd"
import "../../../styles/viewfeed.scss"
import { isAuth, SidebarContext } from "../../auth/ProtectedRoute"

// Expanded interfaces to handle different postable types
interface Member {
  id: number
  name: string
  profession: string
  image: string | null
  profile_code: string
  connections: number
}

interface PageDetails {
  id: number
  name: string
  link: string
  description: string
  industry: string
  size: string
  logo: string
  image: string
  followers: number
}

interface GroupDetails {
  id: number
  name?: string
  link?: string
  description?: string
  logo?: string
  image?: string
  followers?: number
}

interface Like {
  id: number
  name: string
  profession: string
  image: string | null
  profile_code: string
  connections: number
}

interface CommentMember {
  id: number
  name: string
  profession: string
  image: string | null
  profile_code: string
  connections: number
}

interface Comment {
  id: number
  comment: string
  member: CommentMember
  created_at: string
}

interface FeedItem {
  id: number
  postable: {
    type: string
    data?: PageDetails | GroupDetails
    follow_status?: string
  }
  content: string
  media: string[]
  member: Member
  connect_status: string
  like: number
  likes: Like[]
  share: number
  shares: any[]
  comment: number
  comments: Comment[]
  created_at: string
  updated_at: string | null
}

const ViewFeed = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const [feedData, setFeedData] = useState<FeedItem | null>(null)
  const [feedMedia, setFeedMedia] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false)

  useEffect(() => {
    fetchFeedDetails()
  }, [id])

  const fetchFeedDetails = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_HOST_API}manage-feeds/feed/${id}`, {
        headers: {
          Authorization: `Bearer ${isAuth()}`,
        },
      })

      if (response.data.response) {
        const feed = response.data.data
        setFeedData(feed)

        // Process media URLs
        setFeedMedia(feed.media || [])
      } else {
        message.error("Failed to fetch feed details")
        navigate("/admin/feeds")
      }
    } catch (error) {
      console.error("Error fetching feed details:", error)
      message.error("Error fetching feed details")
      navigate("/admin/feeds")
    } finally {
      setLoading(false)
    }
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleString()
  }

  const openImageModal = (imageUrl: string) => {
    setSelectedImage(imageUrl)
    setIsImageModalOpen(true)
  }

  const closeImageModal = () => {
    setSelectedImage(null)
    setIsImageModalOpen(false)
  }

  const renderPostHeader = () => {
    if (!feedData) return null

    // Determine the header based on postable type
    switch (feedData.postable.type) {
      case "member": {
        return (
          <div className="feed-author">
            <div className="avatar">
              <img
                src={
                  feedData.member?.image
                    ? `${process.env.REACT_APP_IMAGE_API_PREFIX}${feedData.member.image}`
                    : "/api/placeholder/40/40"
                }
                alt="Profile"
              />
            </div>
            <div className="author-info">
              <h3 className="author-name">{feedData.member?.name || "User"}</h3>
              <p className="author-title">{feedData.member?.profession || "Member"}</p>
              <p className="post-time">{formatDate(feedData.created_at)}</p>
            </div>
          </div>
        )
      }

      case "page": {
        const pageData = feedData.postable.data as PageDetails
        return (
          <div className="feed-author">
            <div className="avatar">
              <img
                src={
                  pageData?.logo
                    ? `${process.env.REACT_APP_IMAGE_API_PREFIX}${pageData.logo}`
                    : "/api/placeholder/40/40"
                }
                alt="Page Logo"
              />
            </div>
            <div className="author-info">
              <h3 className="author-name">{pageData?.name || "Page"}</h3>
              <p className="author-title">{pageData?.industry || "Industry"}</p>
              <div className="page-meta">
                <span>{pageData?.size || "N/A"} employees</span>
                <span>{feedData.postable.follow_status}</span>
              </div>
              <p className="post-time">{formatDate(feedData.created_at)}</p>
            </div>
          </div>
        )
      }

      case "group": {
        const groupData = feedData.postable.data as GroupDetails
        return (
          <div className="feed-author">
            <div className="avatar">
              <img
                src={
                  groupData?.logo
                    ? `${process.env.REACT_APP_IMAGE_API_PREFIX}${groupData.logo}`
                    : "/api/placeholder/40/40"
                }
                alt="Group Logo"
              />
            </div>
            <div className="author-info">
              <h3 className="author-name">{groupData?.name || "Group"}</h3>
              <p className="author-title">{groupData?.followers || 0} members</p>
              <p className="post-time">{formatDate(feedData.created_at)}</p>
            </div>
          </div>
        )
      }

      default:
        return null
    }
  }

  // If data is still loading, show a loading state
  if (loading) {
    return <div>Loading...</div>
  }

  // If no feed data, show an error or empty state
  if (!feedData) {
    return <div>No feed data found</div>
  }

  return (
    <Fragment>
      <div style={{ padding: "50px" }} className="roless">
        <div className="roless-wrapper">
          <div className="roless--heading">
            <div className="roless--heading-title">
              <h2>Feed details</h2>
            </div>
          </div>
          <div className="feed-cont">
            {/* Back button header */}
            <div className="feed-header">
              <button onClick={() => navigate(-1)} className="back-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M15 18L9 12L15 6"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Back
              </button>
            </div>

            {/* Main feed card */}
            <div className="feed-card">
              {/* Feed content section */}
              <div className="feed-content">
                {/* Author/Page/Group information */}
                {renderPostHeader()}

                {/* Feed text content */}
                <div className="feed-text">
                  {feedData?.content && (
                    <div dangerouslySetInnerHTML={{ __html: feedData.content }} />
                  )}
                </div>

                {/* Feed media content */}
                {feedMedia && feedMedia.length > 0 && (
                  <div className={`feed-media media-count-${Math.min(feedMedia.length, 3)}`}>
                    {feedMedia.map((mediaUrl, index) => {
                      const fullUrl = `${process.env.REACT_APP_IMAGE_API_PREFIX}${mediaUrl}`
                      const isVideo = mediaUrl.toLowerCase().endsWith(".mp4")

                      return (
                        <div
                          key={index}
                          className="media-item"
                          onClick={() => openImageModal(fullUrl)}
                        >
                          {isVideo ? (
                            <video controls>
                              <source src={fullUrl} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={fullUrl} alt={`Media ${index + 1}`} />
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}

                {/* Feed engagement stats */}
                <div className="feed-stats">
                  <div className="stat-item">
                    <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                    <span>{feedData?.like || 0} Likes</span>
                  </div>
                  <div className="stat-item">
                    <i className="fa fa-comment" aria-hidden="true"></i>
                    <span>{feedData?.comment || 0} Comments</span>
                  </div>
                  <div className="stat-item">
                    <i className="fa fa-share" aria-hidden="true"></i>
                    <span>{feedData?.share || 0} Shares</span>
                  </div>
                </div>

                {/* Comments section */}
                {feedData.comments && feedData.comments.length > 0 && (
                  <div className="feed-comments">
                    <h3>Comments</h3>
                    {feedData.comments.map(comment => (
                      <div key={comment.id} className="comment-item">
                        <div className="comment-author">
                          <img
                            src={
                              comment.member.image
                                ? `${process.env.REACT_APP_IMAGE_API_PREFIX}${comment.member.image}`
                                : "/api/placeholder/40/40"
                            }
                            alt={comment.member.name}
                            className="author-avatar"
                          />
                          <div className="author-info">
                            <h4>{comment.member.name}</h4>
                            <p>{comment.member.profession}</p>
                            <span className="comment-time">{formatDate(comment.created_at)}</span>
                          </div>
                        </div>
                        <div className="comment-content">
                          <p>{comment.comment}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Image Modal */}
              {isImageModalOpen && selectedImage && (
                <div className="image-modal">
                  <div className="modal-content">
                    <span className="close" onClick={closeImageModal}>
                      &times;
                    </span>
                    <img src={selectedImage} alt="Enlarged" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ViewFeed
